import { Component } from "react";
import '../css/Hamburger.css';
import logoOpen from "../assets/Hamburger.png"
import logoClose from "../assets/ClosingHamburger.png"
export default class Hamburger extends Component{
  render(){
    return(
      <div className='hamburger' onClick={this.props.toggleOpen}>
        <img src={this.props.isOpen?logoClose:logoOpen} alt="logo di apertura o chiusura menù"></img>
      </div>
    );
  }
}