import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2014/Melania Mazzucco.jpg';
import foto2 from '../../assets/foto2014/Di Battista.jpg';
import foto3 from '../../assets/foto2014/Palco.jpg';
import foto4 from '../../assets/foto2014/Marco Ansaldo.jpg';
import foto5 from '../../assets/foto2014/Cristian Raimo.jpg';
import foto6 from '../../assets/foto2014/Peppe Voltarelli.jpg';

export default class Topic2014 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2014 - XVII EDIZIONE</h3>
        <p><br/>La giuria presieduta Marco Ansaldo e composta da Anna Maria Giordano, Erik Joszef, Christian Raimo e Franco La Cecla ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2014 a:
            <br/><strong>Valerio Millefoglie</strong> con il libro "MONDO PICCOLO". Ed. Laterza<br/></p>
        <p>La Giuria degli studenti ha assegnato una Menzione Speciale a <strong>Giuseppe Catozzella</strong> con il libro “Non dirmi che hai paura". Ed. Feltrinelli</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Melania Mazzucco'/>
          <ImageTopic image={foto2} text='Stefano Di Battista'/>
          <ImageTopic image={foto3} text='Palco'/>
          <ImageTopic image={foto4} text='Marco Ansaldo'/>
          <ImageTopic image={foto5} text='Cristian Raimo'/>
          <ImageTopic image={foto6} text='Peppe Voltarelli'/>
        </div>
      </div>
    );
  }
}