import { Component } from "react";
import { Link } from "react-router-dom";
import '../css/Storico.css';

export default class Storico extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='storico'>
        <h1>Storico</h1>
        <div className='storico-lista-anni'>
          <Link to='/storico/2019' className='noLinkDeco storico-lista-item'>
            <h2>22ª edizione, 2019</h2>
          </Link>
          <Link to='/storico/2018' className='noLinkDeco storico-lista-item'>
            <h2>21ª edizione, 2018</h2>
          </Link>
          <Link to='/storico/2017' className='noLinkDeco storico-lista-item'>
            <h2>20ª edizione, 2017</h2>
          </Link>
          <Link to='/storico/2016' className='noLinkDeco storico-lista-item'>
            <h2>19ª edizione, 2016</h2>
          </Link>
          <Link to='/storico/2015' className='noLinkDeco storico-lista-item'>
            <h2>18ª edizione, 2015</h2>
          </Link>
          <Link to='/storico/2014' className='noLinkDeco storico-lista-item'>
            <h2>17ª edizione, 2014</h2>
          </Link>
          <Link to='/storico/2013' className='noLinkDeco storico-lista-item'>
            <h2>16ª edizione, 2013</h2>
          </Link>
          <Link to='/storico/2012' className='noLinkDeco storico-lista-item'>
            <h2>15ª edizione, 2012</h2>
          </Link>
          <Link to='/storico/2011' className='noLinkDeco storico-lista-item'>
            <h2>14ª edizione, 2011</h2>
          </Link>
          <Link to='/storico/2010' className='noLinkDeco storico-lista-item'>
            <h2>13ª edizione, 2010</h2>
          </Link>
          <Link to='/storico/2009' className='noLinkDeco storico-lista-item'>
            <h2>12ª edizione, 2009</h2>
          </Link>
          <Link to='/storico/2008' className='noLinkDeco storico-lista-item'>
            <h2>11ª edizione, 2008</h2>
          </Link>
          <Link to='/storico/2007' className='noLinkDeco storico-lista-item'>
            <h2>10ª edizione, 2007</h2>
          </Link>
          <Link to='/storico/2006' className='noLinkDeco storico-lista-item'>
            <h2>9ª edizione, 2006</h2>
          </Link>
          <Link to='/storico/2005' className='noLinkDeco storico-lista-item'>
            <h2>8ª edizione, 2005</h2>
          </Link>
          <Link to='/storico/2004' className='noLinkDeco storico-lista-item'>
            <h2>7ª edizione, 2004</h2>
          </Link>
          <Link to='/storico/2003' className='noLinkDeco storico-lista-item'>
            <h2>6ª edizione, 2003</h2>
          </Link>
          <Link to='/storico/2002' className='noLinkDeco storico-lista-item'>
            <h2>5ª edizione, 2002</h2>
          </Link>
          <Link to='/storico/2001' className='noLinkDeco storico-lista-item'>
            <h2>4ª edizione, 2001</h2>
          </Link>
          <Link to='/storico/2000' className='noLinkDeco storico-lista-item'>
            <h2>3ª edizione, 2000</h2>
          </Link>
          <Link to='/storico/1999' className='noLinkDeco storico-lista-item'>
            <h2>2ª edizione, 1999</h2>
          </Link>
          <Link to='/storico/1998' className='noLinkDeco storico-lista-item'>
            <h2>1ª edizione, 1998</h2>
          </Link>
        </div>
      </div>
    )
  }
}