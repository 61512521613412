import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2001/Umberto Tozzi e Di Giacomo.jpg';
import foto2 from '../../assets/foto2001/Umberto Tozzi.jpg';

export default class Topic2001 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2001- IV EDIZIONE</h3>
        <p><br/>La giuria che era presieduta da Mario Tozzi e composta da Antonio Politano, Corrado Ruggeri e Marco Aime ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2001 ex aequo a:
            <br/><strong>Claudio Paglieri</strong> con il libro "L'estate sta finendo". Marsilio,<br/>
            <strong>Riccardo Trizio</strong> con il libro "Tribù bianche perdute". Laterza Editore<br/></p>
        <p>Il premio speciale della giuria dei ragazzi è stato assegnato a <strong>Sergio Ghione</strong> con il libro “L'isola delle tartarughe”. Guanda</p>
        <p></p>Menzioni speciali a <strong>Ettore Mo</strong> con “gulag e altri inferni” (Rizzoli) e <strong>Mempo Giardinelli</strong> con “Finale di romanzo in Patagonia” (Guanda)
        <p>Premio per il miglior reportage di viaggio nel Lazio al giornalista <strong>Filippo Cerrina Ferroni</strong> (Bell'Italia)</p>
        <p>L’edizione del premio è stata dedicata al Kurdistan</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Umberto Tozzi e Francesco Di Giacomo'/>
          <ImageTopic image={foto2} text='Umberto Tozzi'/>
        </div>
      </div>
    );
  }
}

