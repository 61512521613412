import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2010/Gli studenti e Pino cacucci.jpg';
import foto2 from '../../assets/foto2010/Corrado Augias.jpg';
import foto3 from '../../assets/foto2010/Monika Bulaj.jpg';
import foto4 from '../../assets/foto2010/Concerto di Maurizio Sebastianelli.jpg';
import foto5 from '../../assets/foto2010/Luigi 5, Badarà Sek e Basha.jpg';
import foto6 from '../../assets/foto2010/Maurizio Maggiani e Michele De Mieri.jpg';

export default class Topic2010 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2010 - XIII EDIZIONE</h3>
        <p><br/>La giuria era da Presieduta da Paolo Mauri e composta da Michele De Mieri, Pietro Del Soldà, Arturo Di Corinto e Igiaba Scego ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2010 a:
            <br/><strong>Pino Cacucci</strong> con il libro "Le Baleno lo sanno". Ed. Feltrinelli<br/></p>
        <p>La Giuria degli Studenti delle Scuole Superiori di Palestrina, invece, chiamata a valutare le stesse opere, ha assegnato la Menzione Speciale a <strong>Pino Cacucci</strong> con il libro “Le Baleno lo sanno”. Ed. Feltrinelli</p>
        <p>Per la sezione inediti è stato premiato <strong>Alessandro Leggi</strong> con “Nessuno dopo di noi”. Il testo inedito sarà pubblicato dalla casa editrice Vallecchi di Firenze nella collana “off the road”</p>
        <p>Per il concorso “<strong>Click in Viaggio</strong>” la fotografia di <strong>Gianluca Tullio</strong> ed una menzione speciale a <strong>Paolo Romani</strong></p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Gli studenti e Pino cacucci'/>
          <ImageTopic image={foto2} text='Corrado Augias'/>
          <ImageTopic image={foto3} text='Monika Bulaj'/>
          <ImageTopic image={foto4} text='Concerto di Maurizio Sebastianelli'/>
          <ImageTopic image={foto5} text='Luigi Cinque, Badarà Sek e Basha'/>
          <ImageTopic image={foto6} text='Maurizio Maggiani e Michele De Mieri'/>
        </div>
      </div>
    );
  }
}

