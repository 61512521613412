import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2019/Anna Maria Giordano.jpg';
import foto2 from '../../assets/foto2019/Leonardo Piccione vincitore.jpg';
import foto3 from '../../assets/foto2019/Paolo Ciampi premiato.jpg';
import foto4 from '../../assets/foto2019/Giuseppe Cederna.jpg';
import foto5 from '../../assets/foto2019/Giuria e scrittori finalisti.jpg';
import foto6 from '../../assets/foto2019/Diana.jpg';




export default class Topic2019 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2019 - XXII EDIZIONE</h3>
        <p><br/>La giuria presieduta da Loredana Lipperini, Andrea Esposito e Graziano Graziani, ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2019 ex equo  a:
            <br/><strong>Enrico Brizzi</strong> con il libro “Il Cavaliere senza testa”. Ed. Ponte Alle Grazie<br/>
            <strong>Leonardo Piccione</strong> con il libro “Il libro dei vulcani d'Islanda”. Ed. Iperborea</p>
        <p>La Giuria degli studenti ha decretato vincitore lo scrittore <strong>Paolo Ciampi</strong> con il libro “Gli occhi di Firenze”. Ed. Bottega Errante</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Anna Maria Giordano con membri di Lupus in Fabula'/>
          <ImageTopic image={foto2} text='Premiazione di Leonardo Piccione'/>
          <ImageTopic image={foto3} text='Premiazione di Paolo Ciampi'/>
          <ImageTopic image={foto4} text='Giuseppe Cederna'/>
          <ImageTopic image={foto5} text='Giuria e scrittori finalisti'/>
          <ImageTopic image={foto6} text='Diana Agostinello Lupus in Fabula'/>
        </div>
      </div>
    );
  }
}