import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto2 from '../../assets/foto2002/Ilprogramma.jpg';

export default class Topic2002 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2002- V EDIZIONE</h3>
        <p><br/>La giuria che era presieduta da Marino Sinibaldi e composta da Cristina Comencini, Marco Ansaldo, Fabio Venditti e Matteo Pennacchi ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2002 a:
            <br/><strong>Emilio Rigatti</strong> con il libro "La strada per Istanbul". Ediciclo Editore<br/></p>
        <p>Il premio speciale della giuria dei ragazzi è stato assegnato a <strong>Andrea Bocconi</strong> con il libro “Viaggiare e non partire”. Guanda</p>
        <p>Premio per il miglior reportage di viaggio nel Lazio a <strong>Giulio Ielardi</strong></p>
        <p>L’edizione del premio è stata dedicata all'Argentina</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto2} text='Il programma'/>
        </div>
      </div>
    );
  }
}

