import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2000/Fosco Maraini.jpg';
import foto2 from '../../assets/foto2000/Marino Sinibaldi.jpg';

export default class Topic2000 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2000 - III EDIZIONE</h3>
        <p><br/>La giuria che era presieduta da Giorgio Montefoschi e composta da Sandra Petrignani, Guido Rampoldi, Renato Minore e Francesco Di Giacomo ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2000 a:
            <br/><strong>Fosco Maraini</strong> con il libro "Case, amori, universi". Ed. Mondadori<br/></p>
        <p>Menzione speciale a <strong>Matteo Pennacchi</strong> con “Il grande sogno” (PM)</p>
        <p>Premio per il miglior reportage di viaggio nel Lazio alla giornalista <strong>Minna Conti</strong></p>
        <p>Si è tenuto inoltre il secondo “Laboratorio di scrittura creativa, tema il Viaggio” a cura del Prof. Franco Gaudiano.</p>
        <p>L’edizione del premio è stata dedicata all'Afghanistan</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Fosco Maraini'/>
          <ImageTopic image={foto2} text='Marino Sinibaldi'/>
        </div>
      </div>
    );
  }
}

