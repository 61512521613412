import './App.css';
import { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import NavBar from './components/NavBar';
import About from './components/About';
import Contact from './components/Contact';
import Storico from './components/Storico';
import Home from './components/Home';
import Topic2019 from './components/topicsStorico/Topic2019';
import Topic2018 from './components/topicsStorico/Topic2018';
import Topic2017 from './components/topicsStorico/Topic2017';
import Topic2016 from './components/topicsStorico/Topic2016';
import Topic2015 from './components/topicsStorico/Topic2015';
import Topic2014 from './components/topicsStorico/Topic2014';
import Topic2013 from './components/topicsStorico/Topic2013';
import Topic2012 from './components/topicsStorico/Topic2012';
import Topic2011 from './components/topicsStorico/Topic2011';
import Topic2010 from './components/topicsStorico/Topic2010';
import Topic2009 from './components/topicsStorico/Topic2009';
import Topic2008 from './components/topicsStorico/Topic2008';
import Topic2007 from './components/topicsStorico/Topic2007';
import Topic2006 from './components/topicsStorico/Topic2006';
import Topic2005 from './components/topicsStorico/Topic2005';
import Topic2004 from './components/topicsStorico/Topic2004';
import Topic2003 from './components/topicsStorico/Topic2003';
import Topic2002 from './components/topicsStorico/Topic2002';
import Topic2001 from './components/topicsStorico/Topic2001';
import Topic2000 from './components/topicsStorico/Topic2000';
import Topic1999 from './components/topicsStorico/Topic1999';
import Topic1998 from './components/topicsStorico/Topic1998';

class App extends Component {
  render(){
    return(
      <Router>
        <div className="albatros">
          <header className="albatros-header">
            <NavBar/>
          </header>
          <Switch>
          <Route path='/about' component={About}/>
          <Route path='/contatti' component={Contact}/>
          <Route exact path='/storico' component={Storico}/>
          <Route exact path='/storico/2019' component={Topic2019}/>
          <Route exact path='/storico/2018' component={Topic2018}/>
          <Route exact path='/storico/2017' component={Topic2017}/>
          <Route exact path='/storico/2016' component={Topic2016}/>
          <Route exact path='/storico/2015' component={Topic2015}/>
          <Route exact path='/storico/2014' component={Topic2014}/>
          <Route exact path='/storico/2013' component={Topic2013}/>
          <Route exact path='/storico/2012' component={Topic2012}/>
          <Route exact path='/storico/2011' component={Topic2011}/>
          <Route exact path='/storico/2010' component={Topic2010}/>
          <Route exact path='/storico/2009' component={Topic2009}/>
          <Route exact path='/storico/2008' component={Topic2008}/>
          <Route exact path='/storico/2007' component={Topic2007}/>
          <Route exact path='/storico/2006' component={Topic2006}/>
          <Route exact path='/storico/2005' component={Topic2005}/>
          <Route exact path='/storico/2004' component={Topic2004}/>
          <Route exact path='/storico/2003' component={Topic2003}/>
          <Route exact path='/storico/2002' component={Topic2002}/>
          <Route exact path='/storico/2001' component={Topic2001}/>
          <Route exact path='/storico/2000' component={Topic2000}/>
          <Route exact path='/storico/1999' component={Topic1999}/>
          <Route exact path='/storico/1998' component={Topic1998}/>

          <Route path='/' component={Home}/>
        </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
