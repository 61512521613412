import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2007/Ornela Vorspi premiata.jpg';
import foto2 from '../../assets/foto2007/Giuria e Brugnolo.jpg';
import foto3 from '../../assets/foto2007/Giancarlo Possemato e Francesco Altan.jpg';
import foto4 from '../../assets/foto2007/Wu Ming3.jpg';
import foto5 from '../../assets/foto2007/Pubblico alla premiazione.jpg';
import foto6 from '../../assets/foto2007/Concerto1.jpg';

export default class Topic2007 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2007 - X EDIZIONE</h3>
        <p><br/>La giuria che era presieduta da Khaled Fouad Allam, e composta da Giosuè Calaciura (scrittore), Antonio Ficarra (giornalista RAI), Tommaso Pincio (scrittore) e Megan K Williams (giornalista e scrittrice) ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2007 a:
            <br/><strong>Ornela Vorpsi</strong> con il libro "La mano che non mordi". Ed. Einaudi<br/></p>
        <p>Il premio speciale della giuria dei ragazzi è stato assegnato a <strong>Alessandra Montrucchio</strong> con il romanzo “Berlino”. Ed. Feltrinelli</p>
        <p>Vincitore del Premio per la letteratura di viaggio L'albatros “opere prime e inedite”, scelto dalla casa editrice Vallecchi che ne curerà la pubblicazione, ““Before the tourist come” (cartoline dal Bangladesh)” di <strong>Stefania Ragusa</strong></p>
        <p>Il Premio “L’albatros in corto”, il concorso dedicato ai giovani cineasti ed ai loro cortometraggi, è stato assegnato a <strong>Edoardo De Angelis</strong> con “Mistero e passione di Gino Pacino”. Il Presidente di Giuria de “L’albatros in corto” è stato Francesco Amato</p>
        <p>L’edizione del premio è stata dedicata  ai “Popoli visitati durante le dieci edizioni del Premio”</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Ornela Vorpsi premiata'/>
          <ImageTopic image={foto2} text='Giuria e Brugnolo'/>
          <ImageTopic image={foto3} text='Giancarlo Possemato e Francesco Altan'/>
          <ImageTopic image={foto4} text='Wu Ming 5'/>
          <ImageTopic image={foto5} text='Pubblico alla premiazione'/>
          <ImageTopic image={foto6} text='Concerto'/>
        </div>
      </div>
    );
  }
}

