import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2011/Eraldo Affinati.jpg';
import foto2 from '../../assets/foto2011/Giuria (3).jpg';
import foto3 from '../../assets/foto2011/Giancarlo Possemato.jpg';
import foto4 from '../../assets/foto2011/shel shapiro.jpg';
import foto5 from '../../assets/foto2011/Valerio Aprea.jpg';
import foto6 from '../../assets/foto2011/Bookcrossing.jpg';

export default class Topic2011 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2011 - XIV EDIZIONE</h3>
        <p><br/>La giuria era da Presieduta da Giovanni Scipioni e composta da Michele De Mieri, Caterina Bonvicini, Duccio Canestrini e Chiara Valerio ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2011 a:
            <br/><strong>Eraldo Affinati</strong> con il libro "PERGRIN D’AMORE". Ed. Mondadori<br/></p>
        <p>La Giuria degli Studenti delle Scuole Superiori di Palestrina, invece, chiamata a valutare le stesse opere, ha assegnato la Menzione Speciale a <strong>Eraldo Affinati</strong> con il libro “PERGRIN D’AMORE”. Ed. Mondadori</p>
        <p>Per il concorso “<strong>Click in Viaggio</strong>” la fotografia di <strong>Giuseppe Fiasconaro</strong></p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Eraldo Affinati'/>
          <ImageTopic image={foto2} text='Giuria'/>
          <ImageTopic image={foto3} text='Giancarlo Possemato'/>
          <ImageTopic image={foto4} text='Shel Shapiro'/>
          <ImageTopic image={foto5} text='Valerio Aprea'/>
          <ImageTopic image={foto6} text='Bookcrossing'/>
        </div>
      </div>
    );
  }
}

