import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2017/Domenico Quirico il vincitore.jpg';
import foto2 from '../../assets/foto2017/Domenico Quirico e Giuria.jpg';
import foto3 from '../../assets/foto2017/Diana (1).jpg';
import foto4 from '../../assets/foto2017/Gli amici.jpg';
import foto5 from '../../assets/foto2017/Erri De Luca.jpg';
import foto6 from '../../assets/foto2017/Locandina Percorso.jpg';

export default class Topic2017 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2017 - XX EDIZIONE</h3>
        <p><br/>La giuria presieduta da Carmine Abate e composta da Dora Albanese, Davide Frati e Carola Susani ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2017 a:
            <br/><strong>Domenico Quirico</strong> con il libro “Esodo". Ed. Neri Pozza<br/></p>
        <p>La Giuria degli studenti ha decretato vincitore lo scrittore <strong>Paolo Ciampi</strong> con il libro “Per le foreste sacre". Ed. dei Cammini</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Domenico Quirico premiato'/>
          <ImageTopic image={foto2} text='Domenico Quirico e Giuria'/>
          <ImageTopic image={foto3} text='Diana Agostinello Lupus in Fabula'/>
          <ImageTopic image={foto4} text='Gli Amici'/>
          <ImageTopic image={foto5} text='Erri De Luca'/>
          <ImageTopic image={foto6} text='Locandina Percorso'/>
        </div>
      </div>
    );
  }
}