import { Component } from "react";
import '../css/ImageTopic.css';

export default class ImageTopic extends Component{
  render(){
    return(
      <div className='imageTopic-box'>
        <div className='imageTopic-image'>
          <img src={this.props.image} alt='Scatto proveniente dal festival'/>
        </div>
        <p className='imageTopic-imageDescription'>{this.props.text}</p>
      </div>
    );
  }
}