import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2004/Francesco Piccolo.jpg';
import foto2 from '../../assets/foto2004/Giuria (5).jpg';
import foto3 from '../../assets/foto2004/Folco Quilici.jpg';
import foto4 from '../../assets/foto2004/Alloisio e lupus.jpg';
import foto5 from '../../assets/foto2004/Scenografia (1).jpg';
import foto6 from '../../assets/foto2004/Agricantus.jpg';

export default class Topic2004 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2004 - VII EDIZIONE</h3>
        <p><br/>La giuria che era presieduta da Folco Quilici e composta da Elena Stancanelli, Yasemin Taskin, Stefano Curone e Carlo D’Amicis ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2004 a:
            <br/><strong>Francesco Piccolo</strong> con il libro "Allegro Occidentale". Ed. Feltrinelli<br/></p>
        <p>Il premio speciale della giuria dei ragazzi è stato assegnato a <strong>Francesca Giacchè</strong> con il libro “Quaderni del Marocco”. CDA Vivalda Editore</p>
        <p>Vincitore del Premio per la letteratura di viaggio L'albatros “opere prime e inedite”, scelto dalla casa editrice Vallecchi che ne curerà la pubblicazione, “Working holidays” di <strong>Santo Sammartino</strong></p>
        <p>L’edizione del premio è stata dedicata alla Turchia</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Francesco Piccolo'/>
          <ImageTopic image={foto2} text='La Giuria'/>
          <ImageTopic image={foto3} text='Folco Quilici'/>
          <ImageTopic image={foto4} text='Alloisio e lupus'/>
          <ImageTopic image={foto5} text='Scenografia'/>
          <ImageTopic image={foto6} text='Agricantus'/>
        </div>
      </div>
    );
  }
}

