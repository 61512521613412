import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2015/Luigi Nacci premiato.jpg';
import foto2 from '../../assets/foto2015/Giuliano Malatesta.jpg';
import foto3 from '../../assets/foto2015/Dacia Maraini.jpg';
import foto4 from '../../assets/foto2015/Aldo Cazzullo.jpg';
import foto5 from '../../assets/foto2015/Orchestraccia.jpg';
import foto6 from '../../assets/foto2015/Fabrizio Bosso.jpg';
import foto7 from '../../assets/foto2015/Studenti.jpg'

export default class Topic2015 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2015 - XVIII EDIZIONE</h3>
        <p><br/>La giuria presieduta da Anna Maria Giordano e composta da Marco Ansaldo, Marco Balzano, Laura Pugno e Giulia Villoresi ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2015 a:
            <br/><strong>Luigi Nacci</strong> con il libro "ALZATI E CAMMINA". Ediciclo Edizioni<br/></p>
        <p>La Giuria degli studenti ha decretato vincitore lo scrittore <strong>Calude Marthaler</strong> con il libro “Hasta la bicicletta siempre". Ediciclo Edizioni</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Luigi Nacci premiato'/>
          <ImageTopic image={foto2} text='Giuliano Malatesta'/>
          <ImageTopic image={foto3} text='Dacia Maraini'/>
          <ImageTopic image={foto4} text='Aldo Cazzullo'/>
          <ImageTopic image={foto5} text='Orchestraccia'/>
          <ImageTopic image={foto6} text='Fabrizio Bosso'/>
          <ImageTopic image={foto7} text='Studenti'/>
        </div>
        <div className='topic-video'><iframe title='Omaggio Stefano Malatesta' width='100%' height='100%' src='https://www.youtube.com/embed/5X5aST7oYQc'/></div>
      </div>
    );
  }
}