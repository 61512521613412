import { Component } from 'react';
import '../css/NavBarOption.css';

export default class NavBarOption extends Component{
  render(){
    return(
      <div className='header-option'>
        <h3 className='header-option-title'>{this.props.title}</h3>
      </div>
    );
  }
}