import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2012/Carola Susani e Paolo Ciampi.jpg';
import foto2 from '../../assets/foto2012/Giuliana De Donno.jpg';
import foto3 from '../../assets/foto2012/Marino Magliani.jpg';
import foto4 from '../../assets/foto2012/Peppe Volyarelli.jpg';
import foto5 from '../../assets/foto2012/Stefania Scateni e Diana Agostinello.jpg';
import foto6 from '../../assets/foto2012/Scenografia.jpg';

export default class Topic2012 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2012 - XV EDIZIONE</h3>
        <p><br/>La Giuria Ufficiale era presieduta da Stefania Scateni e composta da Paolo Ciampi, Anilda Ibhraimi e Antonella Lattanzi ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2012 a:
            <br/><strong>Carola Susani</strong> con il libro "ERAVAMO BAMBINI ABBASTANZA". MinimumFax Edizioni<br/></p>
        <p>La Giuria degli Studenti delle Scuole Superiori di Palestrina, invece, chiamata a valutare le stesse opere, ha assegnato la Menzione Speciale a <strong>Eddy Cattaneo</strong> con il libro “MONDO VIA TERRA". Ed. Feltrinelli</p>
        <p>Per il concorso “<strong>Click in Viaggio</strong>” la fotografia di <strong>Silvia Midde</strong></p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Carola Susani e Paolo Ciampi'/>
          <ImageTopic image={foto2} text='Giuliana De Donno'/>
          <ImageTopic image={foto3} text='Marino Magliani'/>
          <ImageTopic image={foto4} text='Peppe Volyarelli'/>
          <ImageTopic image={foto5} text='Stefania Scateni e Diana Agostinello'/>
          <ImageTopic image={foto6} text='Scenografia'/>
        </div>
      </div>
    );
  }
}

