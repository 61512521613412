import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2018/Il Vincitore Marino Magliani.jpg';
import foto2 from '../../assets/foto2018/Giuria (2).jpg';
import foto3 from '../../assets/foto2018/Diana e Mauro Covacich.jpg';
import foto4 from '../../assets/foto2018/Diana e Maroccolo.jpg';
import foto5 from '../../assets/foto2018/Paolo Piacentini.jpg';
import foto6 from '../../assets/foto2018/Andrea Chimenti.jpg';
import foto7 from '../../assets/foto2018/Matteo.jpg';

export default class Topic2018 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2018 - XXI EDIZIONE</h3>
        <p><br/>La giuria presieduta da Carmine Abate e composta da Susanna Tartaro, Sergio Garufi e Giuliano Malatesta ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2018 a:
            <br/><strong>Marino Magliani</strong> con il libro “L’esilio dei moscerini danzanti giapponesi”. Ed. Exorma</p>
        <p>La giuria degli studenti ha decretato vincitori ex equo gli scrittori:
           <br/><strong>Federico Pace</strong> con il libro “Controvento". Ed. Einaudi<br/>
            <strong>Giuseppe Catozzella</strong> con il libro “E tu splendi”. Ed. Feltrinelli</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Marino Magliani premiato'/>
          <ImageTopic image={foto2} text='La Giuria'/>
          <ImageTopic image={foto3} text='Diana Agostinello e Mauro Covacich'/>
          <ImageTopic image={foto4} text='Diana Agostinello e Gianni Maroccolo'/>
          <ImageTopic image={foto5} text='Incontro con Paolo Piacentini'/>
          <ImageTopic image={foto6} text='Andrea Chimenti'/>
          <ImageTopic image={foto7} text='Matteo Ferracci'/>
        </div>
      </div>
    );
  }
}