import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2003/Scenografia (2).jpg';
import foto2 from '../../assets/foto2003/Chamni Thpmnee.jpg';
import foto3 from '../../assets/foto2003/Nidi DArac.jpg';
import foto4 from '../../assets/foto2003/Lupus in fabula.jpg';
import foto5 from '../../assets/foto2003/Francesco Di Giacolo e Rodolfo Maltese.jpg';
import foto6 from '../../assets/foto2003/Gabriela.jpg';

export default class Topic2003 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2003- VI EDIZIONE</h3>
        <p><br/>La giuria che era presieduta da Lidia Ravera e composta da Filippo La Porta, Marco Cassini, Antonio Pascale e Emilio Rigatti ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2003 ex aequo a:
            <br/><strong>Paolo Paci</strong> con il libro "Alpi". Ed. Feltrinelli<br/>
            <strong>Paolo Rumiz</strong> con il libro "È Oriente". Ed. Feltrinelli<br/></p>
        <p>Il premio speciale della giuria dei ragazzi è stato assegnato a <strong>Enrico Buonanno</strong> con il libro “Piccola seranata notturna”. Marsilio</p>
        <p>Premio per il miglior reportage di viaggio nel Lazio a <strong>Cinzia Del Maso</strong> con “I colori degli Etruschi” (Bell’Italia)</p>
        <p>L’edizione del premio è stata dedicata all'Altra America</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Scenografia'/>
          <ImageTopic image={foto2} text='Chamni Thpmnee'/>
          <ImageTopic image={foto3} text="Nidi D'Arac"/>
          <ImageTopic image={foto4} text='Lupus in Fabula'/>
          <ImageTopic image={foto5} text='Francesco Di Giacolo e Rodolfo Maltese'/>
          <ImageTopic image={foto6} text='Gabriela Volanti'/>
        </div>
      </div>
    );
  }
}

