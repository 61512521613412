import { Component } from "react";
import '../css/Contact.css';

export default class Contact extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='contact'>
        <h1>Contattaci</h1>
        <div className='contact-box'>
          <p><strong>email</strong>: <a href='mailto:eventialbatros@gmail.com'>eventialbatros@gmail.com</a></p>
        </div>
      </div>
    )
  }
}