import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto1999/Tiziano Terzani.jpg';
import foto2 from '../../assets/foto1999/Dacia Maraini e Stefano Malatesta.jpg';

export default class Topic1999 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 1999 - II EDIZIONE</h3>
        <p><br/>La giuria che era presieduta da Aldo Forbice e composta da Rita Pinci, Ludina Barzini, Renzo Foa e Stefano Malatesta ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 1999 a:
            <br/><strong>Tiziano Terzani</strong> con il libro "In Asia". Longanesi<br/></p>
        <p>Menzione speciale a <strong>Cino Boccazzi</strong> con “La bicicletta di mio padre” (Neri Pozza)</p>
        <p>Premio per il miglior reportage di viaggio nel Lazio al giornalista <strong>Carlo Galimberti</strong></p>
        <p>L’edizione del premio è stata dedicata all'Algeria</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Tiziano Terzani'/>
          <ImageTopic image={foto2} text='Dacia Maraini e Stefano Malatesta'/>
        </div>
      </div>
    );
  }
}

