import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2013/Andrea Scanzi.jpg';
import foto2 from '../../assets/foto2013/Franco La Cecla.jpg';
import foto3 from '../../assets/foto2013/Giuria.jpg';
import foto4 from '../../assets/foto2013/Diana e i premiati.jpg';
import foto5 from '../../assets/foto2013/Tommaso Giartosio.jpg';
import foto6 from '../../assets/foto2013/People.jpg';

export default class Topic2013 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2013 - XVI EDIZIONE</h3>
        <p><br/>La giuria presieduta Francesco Costa e composta da Luigi La Rosa, Alessandro Leogrande, Paolo di Paolo e Vanna Vannuccini ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2013 a:
            <br/><strong>Franco La Cecla</strong> con il libro "INDIAN KISS". Obarrao Edizioni<br/></p>
        <p>La Giuria degli studenti ha assegnato una Menzione Speciale a <strong>Laila Wadia</strong> con il libro “SE TUTTE LE DONNE". Barbera Edizioni</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Andrea Scanzi'/>
          <ImageTopic image={foto2} text='Franco La Cecla'/>
          <ImageTopic image={foto3} text='La Giuria'/>
          <ImageTopic image={foto4} text='Premiazione'/>
          <ImageTopic image={foto5} text='Tommaso Giartosio'/>
          <ImageTopic image={foto6} text='Pubblico'/>
        </div>
      </div>
    );
  }
}