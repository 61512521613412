import { Component } from "react";
import { Link } from 'react-router-dom';
import NavBarOptions from "./NavBarOption";
import '../css/HamMenu.css';

export default class HamMenu extends Component{
  render(){
    return(
      <div className={this.props.isOpen?'hamMenu open':'hamMenu'} onClick={this.props.toggleOpen}>
        <Link to="/" className='noLinkDeco'>
          <NavBarOptions title='PAGINA INIZIALE'/>
        </Link>
        <Link to="/about" className='noLinkDeco'>
          <NavBarOptions title='CHI SIAMO'/>
        </Link>          
        <Link to="/storico" className='noLinkDeco'>
          <NavBarOptions title='STORICO EDIZIONI'/>
        </Link>
        <Link to="/contatti" className='noLinkDeco'>
          <NavBarOptions title='CONTATTI'/>
        </Link>
      </div>
    );
  }
}