import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2006/Emanuele Trevi .jpg';
import foto2 from '../../assets/foto2006/Paolo Rumiz e Emilio Rigatti.jpg';

export default class Topic2006 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2006 - IX EDIZIONE</h3>
        <p><br/>La giuria che era presieduta dal prof. Luigi M. Lombardi Satriani e composta da Arnaldo Colasanti (critico letterario), Maria Cuffaro (giornalista Rai 3), Loredana Lipperini (giornalista e scrittrice) e Romana Petri (scrittrice) ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2006 a:
            <br/><strong>Emanuele Trevi</strong> con il romanzo "l'onda del porto". Laterza<br/></p>
        <p>Il premio speciale della giuria dei ragazzi è stato assegnato a <strong>Emilio Rigatti</strong> con il libro “Yo no soy gringo”. Ediciclo editore</p>
        <p>Vincitore del Premio per la letteratura di viaggio L'albatros “opere prime e inedite”, scelto dalla casa editrice Vallecchi che ne curerà la pubblicazione, “Filmistan, effetto Tibet” di <strong>Nico Bosa</strong></p>
        <p>Il Premio “L’albatros in corto”, il concorso dedicato ai giovani cineasti ed ai loro cortometraggi, è stato assegnato a <strong>Francesco Amato</strong> con “Il nano più alto del mondo” e <strong> Stefano Bertelli</strong> con “To Day”. La giuria era composta da Cosimo Calamini (sceneggiatore), Marco Michiorri (video editor) e Marco Spoletini (AMC).</p>
        <p>L’edizione del premio è stata dedicata ai “Popoli del deserto”</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Emanuele Trevi'/>
          <ImageTopic image={foto2} text='Paolo Rumiz ed Emilio Rigatti'/>
        </div>
      </div>
    );
  }
}

