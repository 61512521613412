import { Component } from "react";

import '../css/Home.css';
import mongo from '../assets/mongolfiera.png';
import logoBCL from '../assets/sponsor/positivo-quadrato.png';
import logoLupus from '../assets/sponsor/Logo Vettoriale settembre2015.png';
import logoPalestrina from '../assets/sponsor/palestrina PER STAMPA 1.png';
import logoVivigas from '../assets/sponsor/VIVIgas_logo_orizz.png';


export default class Home extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='home'>
        <div className='home-title'>
          <div className='home-title-name'>
            <h2>l'albatros<br/></h2>
            <h3 className='home-title-name-2'>Città di Palestrina</h3>
            <h5 className='home-title-name-2'>Premio e Festival per la letteratura di viaggio</h5>
              <a href='https://drive.google.com/uc?export=download&id=19yfF6quyOZB8gpl2F84gEx303V45ZuyP'>
                <button className='home-title-button'>Bando 2019</button>
              </a>
          </div>
          <img src={mongo} alt='mongolfiera'/>
        </div>
        <div className='home-comunicato'>
          <p>Ultimo comunicato:</p>
          <p className='p-home-comunicato'>Ci siamo presi una pausa. Non l'abbiamo scelto, come tanti, ma non siamo stati fermi. Abbiamo viaggiato. Abbiamo esplorato nuovi mondi, visto orizzonti diversi. Lo abbiamo fatto attraverso i libri e la fantasia. Stiamo lavorando per tornare e stringere mani, incrociare sguardi e la meraviglia in presenza.</p>
        </div>
        <div className='home-sponsor'>
          <h3 className='home-sponsor-title'>Partner</h3>
          <div className='home-sponsor-images'>
            <img src={logoBCL} alt='Logo banca centro lazio'/>
            <img src={logoLupus} alt='Logo Lupus in Fabula'/>
            <img src={logoPalestrina} alt='Logo Comune di Palestrina'/>
            <img src={logoVivigas} alt='Logo Vivigas'/>
          </div>
          <p className='home-sponsor-diritto'>Foto di Gianluca Gasbarri</p>
        </div>
      </div>
    )
  }
}