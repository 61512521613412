import { Component } from "react";
import '../css/About.css';

export default class About extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='about'>
        <h1 className='about-titolo'>Il premio l'albatros di Palestrina</h1>
        <div className='about-glossario'>
          <h3>Glossario</h3>
          <ul dir='rtl'>
            <li className='noLinkDeco'><a href='#idea'>L'idea</a></li>
            <li><a href='#chisiamo'>Chi siamo</a></li>
            <li><a href='#parlatodinoi'>Si sono occupati di noi</a></li>
            <li><a href='#artistivenuti'>Gli artisti intervenuti nelle varie edizioni</a></li>
            <li><a href='#ancora'>Ancora...</a></li>
          </ul>
        </div>
        <div id='idea' className='about-idea'>
          <h2>L'IDEA</h2>
          <div className='about-idea-paragrafo'>
            <h3>COME NASCE L'IDEA</h3>
            <p>Il Lazio e la Provincia di Roma, in particolare, sono stati esempio, nel corso della storia millenaria di convivenza possibile tra diverse culture.
Ciò è accaduto sia attraverso gli eventi speciali, politici ed economici che hanno fatto del Lazio e di Roma il fulcro di uno dei più vasti imperi della storia, sia attraverso significativi fenomeni collettivi ed individuali che hanno visto tali luoghi meta privilegiata di viaggi di scoperta e di conoscenza dall’età classica ad oggi.
Palestrina ed il suo Tempio della Fortuna Primigenia hanno seguito tale storia; anzi, le peculiarità del culto e  dei suoi luoghi, la stessa collocazione geografica di fronte all’antico porto di Antium ed all’imboccatura della valle del fiume Sacco, corridoio naturale tra nord e sud del paese, ne hanno fatto luogo privilegiato di incontro di culture e popoli diversi.
</p>
          </div>
          <div className='about-idea-paragrafo'>
            <h3>IL VIAGGIO</h3>
            <p>L’idea che nel corso degli anni abbiamo sviluppato si ancora a questa storia ed a queste caratteristiche, attraverso un’iniziativa che si propone di valorizzarle intorno ad un tema da sempre connaturato all’uomo ed alla sua ricerca esteriore ed interiore: “il viaggio”
                Il viaggio raccontato, interpretato, immaginato, attraverso le molteplici forme espressive proprie del linguaggio delle parole e delle immagini: la letteratura, il cinema, la fotografia e la musica.
                Il progetto permette di raccogliere e selezionare le produzioni letterarie dell’anno in corso, con il duplice scopo di <strong>divulgare e premiare</strong>, attraverso il giudizio di una autorevole e qualificata giuria, le opere ritenute migliori.
                Oltre la Giuria ufficiale viene creata una giuria parallela composta dai ragazzi delle scuole superiori del nostro territorio che caratterizza ormai la forte partecipazione degli studenti al nostro progetto, assolvendo ad una delle finalità del premio letterario: <strong>la promozione della lettura e la discussione critica dei testi tra i giovani giurati</strong>. 
                Al fine di rendere il mondo intero una biblioteca, viene attivato il BOOKCROSSING con la “liberazione” e fruizione di centinaia di testi. Abbiamo donato alla Biblioteca di Palestrina circa 600 libri.</p> 
            <p>Nelle passate edizioni abbiamo coinvolto tra gli altri: Dacia e Fosco Maraini, Tiziano Terzani, Stefano Malatesta, Marco Aime, Mario Tozzi, Francesco Di Giacomo, Marino Sinibaldi, Cristina Comencini, Lidia Ravera, Alessandro Leogrande, Simona Vinci, Antonio Pascale, Simonetta Fiori, Eraldo Affinati, Paolo Di Paolo, Cristian Raimo, Folco Quilici, Francesco Piccolo, Giuseppe Cederna, Emanuele Trevi, Stefano Ardito, Aurelio Picca, K.F.Allam, Elena Stancanelli, Marco Ansaldo, Romana Petri, Wu Ming5, Melania Mazzucco, Paolo Rumiz, Massimo Carlotto, Carola Susani, Franco La Cecla, Igiaba Scego, Loredana Lipperini, Pino Cacucci, Andrea Bocconi, Chiara Valerio, Paolo Ciampi, Antonella Lattanzi, Nicola Lagioia, Corrado Augias, Stefano Benni, Stefania Ragusa, Marco Balzano, Luigi Nacci e Domenico Quirico.
                Reading letterari, bookcrossing, laboratori di scrittura creativa, incontri con gli autori, animeranno l’intero evento. Lo straordinario patrimonio artistico e archeologico del nostro territorio sarà la location naturale.</p>
          </div>
          <div className='about-idea-paragrafo'>
            <h3>INFORMAZIONE, DIFFUSIONE E PUBBLICIZZAZIONE DELL'EVENTO</h3>
            <p>La nostra esperienza che ci ha visti protagonisti di vari eventi culturali, ci ha permesso di effettuare  studi campione relativi al pubblico che ha partecipato alle nostre iniziative. Vengono utilizzati strumenti di comunicazione innovativi e flessibili sia sul piano locale che nazionale, come: sito internet dell'iniziativa, Social Network, sinergie con media partner di livello locale e nazionale.</p>
          </div>
          <div className='about-idea-paragrafo'>
            <h3>Il Festival nasce da una idea dell’associazione culturale Lupus in Fabula in collaborazione con il Comune di Palestrina.</h3>
          </div>
        </div>
        <div id='chisiamo' className='about-chisiamo'>
          <h2>CHI SIAMO</h2>
          <div className='about-idea-paragrafo'>
            <h3>Associazione culturale Lupus in Fabula</h3>
            <p>L'Associazione nasce nell'ottobre del 1995 con il forte intento di promuovere iniziative capaci di allargare i momenti di partecipazione alla varie espressioni artistiche e culturali. Letteratura, solidarietà, musica, cinema, poesia, fotografia sono i temi al centro delle attività di Lupus in Fabula.</p>
          </div>
        </div>
        <div id='parlatodinoi' className='about-chisiamo'>
          <h2>SI SONO OCCUPATI DI NOI</h2>
          <div className='about-idea-paragrafo'>
            <p>Radio RAI, Zapping, IsoRadio, RadioRai 2, RadioRai 3, TG1, TGR 3 Regionale,  TeleMontecarlo, Tele56, Radio Onda Libera, La Repubblica, Il Corriere della Sera, Il Messaggero, Il Manifesto, L’Avvenire, Avvenimenti, Avventure nel Mondo, Viaggiare e Alice.it.</p>
          </div>
        </div>
        <div id='artistivenuti' className='about-chisiamo'>
          <h2>GLI ARTISTI INTERVENUTI NELLE VARIE EDIZIONI</h2>
          <div className='about-idea-paragrafo'>
            <p>Novalia, Francesco Di Giacomo, Rodolfo Maltese, Nicola Campogrande, Giuliana De Donno, Andrea Chimenti, Massimo Fontoni, Annalisa Comes, Mauro Orselli, Radioderwish, Nidi D’Arac, Blue Bossa, Segni Jazz Ensamble, Philadelphia Youth Virtuosism, Laccone.com, Pseudofonia, Raffaello Simeoni, Agricantus, Pap Yery Sam, Baba Sissoko, Orchestra di Piazza Vittorio, Trilok Gurtu, Ambrogio Sparagna, Wu Ming e Yo Yo Mundi, Stefano Benni, Luigi Cinque, Almamegretta, Corrado Augias, Stefano Di Battista, Fabrizio Bosso.</p>
          </div>
        </div>
        <div id='ancora' className='about-chisiamo'>
          <h2>ANCORA...</h2>
          <div className='about-idea-paragrafo'>
            <p>Abbiamo ospitato le mostre di Chamni Tipmenee (Thailandia), Guido Contini (Italia), Gabriela Volanti (Germania) e Greenpeace.</p>
            <p>Hanno collaborato le Ambasciate della Thailandia, della Turchia e l’Istituto della cultura Polacca.</p>
          </div>
        </div>
      </div>
    )
  }
}