import { Component } from 'react';
import { Link } from 'react-router-dom';
import NavBarOptions from './NavBarOption';
import logo from '../assets/Logo Albatros.png';
import '../css/NavBar.css';
import Hamburger from './Hamburger';
import HamMenu from './HamMenu';

export default class NavBar extends Component{
  constructor(){
    super()
    
    this.state={
      isOpen: false,
    }

    this.toggleOpen=this.toggleOpen.bind(this);
  }

  toggleOpen(){
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  render(){
    return(
      <div className='headerOG'>
        <div className='header-left'>
          <img src={logo} alt="logo dell'associazione albatros"></img>
        </div>
        <div className='header-right'>
          <div className='mobile-icon'>
            <Hamburger isOpen={this.state.isOpen} toggleOpen={this.toggleOpen}/>
            <HamMenu isOpen={this.state.isOpen} toggleOpen={this.toggleOpen}/>
          </div>
          <div className='desktop-menu'>
            <Link to="/" className='noLinkDeco'>
              <NavBarOptions title='PAGINA INIZIALE'/>
            </Link>
            <Link to="/about" className='noLinkDeco'>
              <NavBarOptions title='IL PREMIO'/>
            </Link>
            <Link to="/storico" className='noLinkDeco'>
              <NavBarOptions title='STORICO EDIZIONI'/>
            </Link>
            <Link to="/contatti" className='noLinkDeco'>
              <NavBarOptions title='CONTATTI'/>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}