import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2005/Giuseppe Cederna (1).jpg';
import foto2 from '../../assets/foto2005/Giuria (4).jpg';
import foto3 from '../../assets/foto2005/Melania Mazzucco e Simonetta Fiori.jpg';
import foto4 from '../../assets/foto2005/Paolo Rumiz.jpg';
import foto5 from '../../assets/foto2005/Scenografia Africa 2.jpg';
import foto6 from '../../assets/foto2005/Incontro letterario 3.jpg';

export default class Topic2005 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2005 - VIII EDIZIONE</h3>
        <p><br/>La giuria che era presieduta dalla giornalista Simonetta Fiori (presidente), e composta dal Premio Strega 2003 Melania Mazzucco, dallo scrittore e critico letterario Emanuele Trevi, dal Superpremio Grinzane Cavour 1999 Aurelio Picca e dall’autore teatrale e scrittore di viaggi Andrea Bocconi ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2005 a:
            <br/><strong>Giuseppe Cederna</strong> con il romanzo "Il grande viaggio". Ed. Feltrinelli<br/></p>
        <p>Il premio speciale della giuria dei ragazzi è stato assegnato a <strong>Francesca De Carolis</strong> con il libro “India Appunti di Viaggio”</p>
        <p>Vincitore del Premio per la letteratura di viaggio L'albatros “opere prime e inedite”, scelto dalla casa editrice Vallecchi che ne curerà la pubblicazione, “Metà Cielo, Mezza Luna” di <strong>Maria Silvia Codecasa</strong></p>
        <p>Il Premio “L’albatros in corto”, il concorso dedicato ai giovani cineasti ed ai loro cortometraggi, è stato assegnato a <strong>Lorenzo Adorisio</strong> con “Chora”. La giuria era composta da Fabiana Sargentini, Roberto Perpignani e il critico cinematografico Flavio De Bernardinis</p>
        <p>L’edizione del premio è stata dedicata all'Africa</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Giuseppe Cederna'/>
          <ImageTopic image={foto2} text='La Giuria'/>
          <ImageTopic image={foto3} text='Melania Mazzucco e Simonetta Fiori'/>
          <ImageTopic image={foto4} text='Paolo Rumiz'/>
          <ImageTopic image={foto5} text='Scenografia Africa'/>
          <ImageTopic image={foto6} text='Incontro letterario'/>
        </div>
      </div>
    );
  }
}

