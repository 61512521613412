import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2008/Filippo Tuena.jpg';
import foto2 from '../../assets/foto2008/Lupus, giurati ed autori.jpg';
import foto3 from '../../assets/foto2008/Serata finale.jpg';
import foto4 from '../../assets/foto2008/Lupus.jpg';
import foto5 from '../../assets/foto2008/Diana Agostinello.jpg';
import foto6 from '../../assets/foto2008/Luigi 5.jpg';

export default class Topic2008 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2008 - XI EDIZIONE</h3>
        <p><br/>La giuria che era Presieduta da Luigi Cinque (Presidente) Errico Buonanno, Tiziana De Rogatis, Luca Mastrantonio e Carola Susani ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2008 a:
            <br/><strong>Filippo Tuena</strong> con il libro "Ultimo parallelo". Ed. Rizzoli<br/></p>
        <p>La Giuria degli Studenti delle Scuole Superiori di Palestrina, invece, chiamata a valutare le stesse opere, ha assegnato la Menzione Speciale a <strong>Heman Zed</strong> con il libro “la cortina di marzapane”. Il maestrale</p>
        <p>Il Premio “L’albatros in corto”, il concorso dedicato ai giovani cineasti ed ai loro cortometraggi, è stato assegnato a <strong>Francesco Amato</strong> con “Autoritratto”. Il Presidente di Giuria de “L’albatros in corto” è stato Claudio Calibotti</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Filippo Tuena'/>
          <ImageTopic image={foto2} text='Lupus in Fabula, giurati ed autori'/>
          <ImageTopic image={foto3} text='Serata finale'/>
          <ImageTopic image={foto4} text='Lupus in Fabula'/>
          <ImageTopic image={foto5} text='Diana Agostinello'/>
          <ImageTopic image={foto6} text='Luigi Cinque'/>
        </div>
      </div>
    );
  }
}

