import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto1998/Stanislao Nievo.jpg';
import foto2 from '../../assets/foto1998/lUPUS IN FABULA (1).jpg';
import foto3 from '../../assets/foto1998/AQUILONI.jpg';
import foto4 from '../../assets/foto1998/Cartolina.jpg';

export default class Topic1998 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 1998 - I EDIZIONE</h3>
        <p><br/>La giuria che era presieduta da Stanislao Nievo composta da Vera Slepoj, Francesco Cabras, Gino Pennacchi e Lillo Perri ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 1998 a:
            <br/><strong>Stefano Malatesta</strong> con il libro "il Cammello Battriano". Neri Pozza<br/></p>
        <p>Premio per il miglior reportage di viaggio nel Lazio a <strong>Stefano e Fabrizio Ardito</strong> per il reportage "Un mondo di sorprese tra Roma e il Circeo" (Airone)</p>
        <p>L’edizione del premio è stata dedicata al Tibet</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Stanislao Nievo'/>
          <ImageTopic image={foto2} text='Lupus in Fabula'/>
          <ImageTopic image={foto3} text='Acquiloni'/>
          <ImageTopic image={foto4} text='Cartolina'/>
        </div>
      </div>
    );
  }
}

