import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2009/Simona Vinci.jpg';
import foto2 from '../../assets/foto2009/Antonio Maresca.jpg';
import foto3 from '../../assets/foto2009/Avion Travel.jpg';
import foto4 from '../../assets/foto2009/Stefano Benni.jpg';
import foto5 from '../../assets/foto2009/Scenografia (1).jpg';
import foto6 from '../../assets/foto2009/Almamegretta.jpg';

export default class Topic2009 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2009 - XII EDIZIONE</h3>
        <p><br/>La giuria che era da Presieduta da Filippo Tuena (Presidente), da Francesco Abate, Carmine Fotia, Maria Ida Gaeta e Giampaolo Visetti ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2009 ex aequo a:
            <br/><strong>Simona Vinci</strong> con il libro "Nel bianco". Ed. Rizzoli<br/>
            <strong>Antonio Moresco</strong> con "Zingari di merda". Ed. Effigie<br/></p>
        <p>La Giuria degli Studenti delle Scuole Superiori di Palestrina, invece, chiamata a valutare le stesse opere, ha assegnato la Menzione Speciale a <strong>Simona Vinci</strong> con il libro “Nel bianco”. Ed. Rizzoli</p>
        <p>Per la sezione inediti è stato premiato <strong>Michele Cucuzzella</strong> con “Per capire meglio la Birmania”. Il testo inedito sarà pubblicato dalla casa editrice Vallecchi di Firenze nella collana “off the road”</p>
        <p>Per il concorso “<strong>Click in Viaggio</strong>” la fotografia di <strong>David Torrason i Cerdà</strong>.</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Simona Vinci'/>
          <ImageTopic image={foto2} text='Antonio Maresca'/>
          <ImageTopic image={foto3} text='Avion Travel'/>
          <ImageTopic image={foto4} text='Stefano Benni'/>
          <ImageTopic image={foto5} text='Scenografia'/>
          <ImageTopic image={foto6} text='Almamegretta'/>
        </div>
      </div>
    );
  }
}

