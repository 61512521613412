import { Component } from "react";
import '../../css/TopicGeneral.css';
import ImageTopic from "../ImageTopic";
import foto1 from '../../assets/foto2016/Noo Saro-Wiwa premiata.jpg';
import foto2 from '../../assets/foto2016/I Premiati.jpg';
import foto3 from '../../assets/foto2016/Giuria (1).jpg';
import foto4 from '../../assets/foto2016/Palco (1).jpg';
import foto5 from '../../assets/foto2016/Pietro Spirito.jpg';
import foto6 from '../../assets/foto2016/Noo Saro-Wiwa.jpg';

export default class Topic2016 extends Component{
  componentDidMount(){
    window.scrollTo(0,0);
  }
  render(){
    return(
      <div className='topic'>
        <h3 className='topic-titolo'>ASSEGNATO IL PREMIO PER LA LETTERATURA DI VIAGGIO<br />L’albatros - Città di Palestrina 2016 - XIX EDIZIONE</h3>
        <p><br/>La giuria presieduta da Luigi Nacci e composta da Simona Baldanzi, Paola De Angelis, Gianfranco Franchi e Emiliano Sbaraglia ha assegnato il Premio per la letteratura di viaggio l’albatros - Città di Palestrina 2016 a:
            <br/><strong>Noo Saro-Wiwa</strong> con il libro "In cerca di Transwonderland". Ed. 66TH A2ND<br/></p>
        <p>La Giuria degli studenti ha decretato vincitore la scrittrice <strong>Elena Sacco</strong> con il libro “Siamo Liberi". Ed. Chiarelettere</p>
        <div className='topic-foto-list'>
          <ImageTopic image={foto1} text='Noo Saro-Wiwa premiata'/>
          <ImageTopic image={foto2} text='I Premiati'/>
          <ImageTopic image={foto3} text='La Giuria'/>
          <ImageTopic image={foto4} text='Il palco'/>
          <ImageTopic image={foto5} text='Pietro Spirito'/>
          <ImageTopic image={foto6} text='Noo Saro-Wiwa'/>
        </div>
      </div>
    );
  }
}